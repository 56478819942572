<template>
  <div>
    <Button
      label="New"
      icon="pi pi-plus"
      class="p-button-primary"
      role="show-dialog"
      @click="showDialog"
    />
    <Dialog
      v-model:visible="shouldShowDialog"
      header="Add new event"
      :modal="true"
      class="p-fluid"
    >
      <EventForm v-model="event" />

      <template #footer>
        <Button
          label="Fill with mock data"
          icon="pi pi-user"
          class="p-button-secondary"
          role="fill-mock-data"
          :disabled="isLoading"
          @click="fillMockData"
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          role="hide-dialog"
          :disabled="isLoading"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-primary"
          role="add-event"
          :disabled="isLoading"
          :loading="isLoading"
          @click="save"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import EventForm from '@/components/EventForm.vue'
import useEvent from '@/use/useEvent'
import { reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'EventAddDialog',
  components: { EventForm },
  setup() {
    const { addEvent, fetchEvents, isLoading } = useEvent()
    const shouldShowDialog = ref(false)
    const isDev = process.env.NODE_ENV === 'development'
    const v$ = useVuelidate()

    const event = reactive({})

    const initEvent = () => {
      event.name = ''
      event.region = ''
      event.date_start = ''
      event.date_end = ''
      event.description = ''
      event.capacity = ''
      event.price = ''
      event.lodge_id = ''
    }
    initEvent()

    const showDialog = () => {
      shouldShowDialog.value = true
    }
    const hideDialog = () => {
      shouldShowDialog.value = false
    }

    const save = async () => {
      const isFormValid = await v$.value.$validate()
      if (!isFormValid) return

      addEvent(event).then(() => {
        fetchEvents()
        hideDialog()
        initEvent()
        v$.value.$reset()
      })
    }

    const fillMockData = () => {
      event.name = 'Demo Event'
      event.region = 'Aargau'
      event.date_start = '2022-02-22'
      event.date_end = '2022-02-23'
      event.description = 'This is a demo event'
      event.capacity = 6
      event.price = 666
      event.lodge_id = 1
    }

    return {
      event,
      shouldShowDialog,
      showDialog,
      hideDialog,
      save,
      isLoading,
      isDev,
      fillMockData,
      v$
    }
  }
}
</script>
