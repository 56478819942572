import useSupabase from '@/use/useSupabase'
import { readonly, ref } from 'vue'

const { supabase } = useSupabase()
const lodges = ref([])

export default () => {
  const isLoading = ref(false)

  const fetchLodges = async () => {
    isLoading.value = true
    try {
      const { data } = await supabase.from('lodge').select().order('id')
      lodges.value = data
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }
  fetchLodges()

  return {
    lodges: readonly(lodges),
    isLoading: readonly(isLoading)
  }
}
