<template>
  <div>
    <Button
      label="Delete selected"
      icon="pi pi-trash"
      class="p-button-secondary"
      role="show-dialog"
      :disabled="isSelectButtonDisabled"
      @click="showDialog"
    />

    <Dialog
      v-model:visible="shouldShowDialog"
      header="Confirm delete events"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span> Are you sure you want to delete the selected events? </span>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          role="hide-dialog"
          :disabled="isLoading"
          @click="hideDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-primary"
          role="delete-event"
          :disabled="isLoading"
          :loading="isLoading"
          @click="deleteSelectedEvents"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import useEvent from '@/use/useEvent'
import { ref, computed, watch } from 'vue'

export default {
  name: 'EventDeleteSelectedDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectedEventsIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['hideDialog', 'clearSelection'],
  setup(props, { emit }) {
    const { fetchEvents, deleteEvents, isLoading } = useEvent()
    const shouldShowDialog = ref(false)

    const isVisible = computed({
      get() {
        return shouldShowDialog.value
      },
      set(value) {
        shouldShowDialog.value = value
      }
    })
    watch(
      () => props.visible,
      (value) => {
        isVisible.value = value
      }
    )

    const showDialog = () => {
      shouldShowDialog.value = true
    }
    const hideDialog = () => {
      shouldShowDialog.value = false
      emit('hideDialog')
    }

    const isSelectButtonDisabled = computed(
      () => props.selectedEventsIds.length === 0
    )

    const deleteSelectedEvents = async () => {
      deleteEvents(props.selectedEventsIds).then(() => {
        fetchEvents()
        hideDialog()
        emit('clearSelection')
      })
    }

    return {
      shouldShowDialog,
      isSelectButtonDisabled,
      showDialog,
      hideDialog,
      isLoading,
      deleteSelectedEvents
    }
  }
}
</script>
