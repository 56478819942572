<template>
  <div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="first_name">Name</label>
        <InputText
          id="name"
          v-model="eventModel.name"
          data-testid="name"
          autofocus
          :class="{ 'p-invalid': v$.name.$errors.length > 0 }"
          @blur="v$.name.$touch"
        />
        <div v-for="error of v$.name.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="last_name">Region</label>
        <InputText
          id="region"
          v-model="eventModel.region"
          data-testid="region"
          :class="{ 'p-invalid': v$.region.$errors.length > 0 }"
          @blur="v$.region.$touch"
        />
        <div v-for="error of v$.region.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="street">Start date</label>
        <Calendar
          id="date_start"
          v-model="eventModel.date_start"
          data-testid="date_start"
          :class="{ 'p-invalid': v$.date_start.$errors.length > 0 }"
          autocomplete="off"
          date-format="yy-mm-dd"
          @date-select="onDateSelectStart"
          @blur="v$.date_start.$touch"
        />
        <div v-for="error of v$.date_start.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
      <div class="field col">
        <label for="street_no">End date</label>
        <Calendar
          id="date_end"
          v-model="eventModel.date_end"
          data-testid="date_end"
          :class="{ 'p-invalid': v$.date_end.$errors.length > 0 }"
          autocomplete="off"
          date-format="yy-mm-dd"
          @date-select="onDateSelectEnd"
          @blur="v$.date_end.$touch"
        />
        <div v-for="error of v$.date_end.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="zip">Description</label>
        <InputText
          id="description"
          v-model="eventModel.description"
          data-testid="zip"
          :class="{ 'p-invalid': v$.description.$errors.length > 0 }"
          @blur="v$.description.$touch"
        />
        <div v-for="error of v$.description.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="city">Capacity</label>
        <InputText
          id="capacity"
          v-model="eventModel.capacity"
          data-testid="city"
          :class="{ 'p-invalid': v$.capacity.$errors.length > 0 }"
          @blur="v$.capacity.$touch"
        />
        <div v-for="error of v$.capacity.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
      <div class="field col">
        <label for="city">Price</label>
        <InputText
          id="price"
          v-model="eventModel.price"
          data-testid="price"
          :class="{ 'p-invalid': v$.price.$errors.length > 0 }"
          @blur="v$.price.$touch"
        />
        <div v-for="error of v$.price.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="country" class="mb-3">Lodge</label>
        <Dropdown
          id="lodge"
          v-model="eventModel.lodge_id"
          data-testid="lodge"
          :options="lodges"
          option-label="name"
          option-value="id"
          placeholder="Select a lodge"
          :class="{ 'p-invalid': v$.lodge_id.$errors.length > 0 }"
          @blur="v$.lodge_id.$touch"
        />
        <div v-for="error of v$.lodge_id.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useLodge from '@/use/useLodge'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { computed } from 'vue'
import { format } from 'date-fns'

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { lodges } = useLodge()

    const eventModel = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const rules = {
      name: { required },
      region: { required },
      date_start: { required },
      date_end: { required },
      description: { required },
      capacity: { required },
      price: { required },
      lodge_id: { required }
    }
    const v$ = useVuelidate(rules, eventModel)

    const onDateSelectStart = (newDate) =>
      (eventModel.value.date_start = format(newDate, 'yyyy-MM-dd'))

    const onDateSelectEnd = (newDate) =>
      (eventModel.value.date_end = format(newDate, 'yyyy-MM-dd'))

    return {
      lodges,
      eventModel,
      v$,
      onDateSelectStart,
      onDateSelectEnd
    }
  }
}
</script>
