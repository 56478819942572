<template>
  <div>
    <Dialog
      v-model:visible="isVisible"
      header="Edit event"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <EventForm v-if="eventState.event !== null" v-model="eventState.event" />

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          role="hide-dialog"
          :disabled="isLoading"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-primary"
          role="edit-event"
          :disabled="isLoading"
          :loading="isLoading"
          @click="save"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import EventForm from '@/components/EventForm.vue'
import useEvent from '@/use/useEvent'
import { ref, computed, watch, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import omit from 'lodash/omit'

export default {
  name: 'EventEditDialog',
  components: { EventForm },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    eventData: {
      type: Object,
      default: null
    }
  },
  emits: ['hideDialog'],
  setup(props, { emit }) {
    const { isLoading, updateEvent, fetchEvents } = useEvent()
    const shouldShowDialog = ref(false)
    const v$ = useVuelidate()
    const eventState = reactive({
      event: null
    })

    const isVisible = computed({
      get() {
        return shouldShowDialog.value
      },
      set(value) {
        shouldShowDialog.value = value
      }
    })

    watch(
      [() => props.visible, () => props.eventData],
      ([visibleValue, eventDataValue]) => {
        isVisible.value = visibleValue
        eventState.event = eventDataValue
      }
    )

    const hideDialog = () => {
      emit('hideDialog')
    }

    const save = async () => {
      const isFormValid = await v$.value.$validate()
      if (!isFormValid) return
      const updatedEvent = omit(eventState.event, [
        'id',
        'lodge',
        'updated_at',
        'created_at'
      ])
      updateEvent(updatedEvent, eventState.event.id).then(() => {
        fetchEvents()
        hideDialog()
        v$.value.$reset()
      })
    }

    return {
      shouldShowDialog,
      hideDialog,
      save,
      isLoading,
      isVisible,
      eventState,
      v$
    }
  }
}
</script>
