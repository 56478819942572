<template>
  <EventEditDialog
    :visible="shouldShowEditDialog"
    :event-data="eventState.event"
    @hide-dialog="hideEditDialog"
  />
  <EventDeleteDialog
    :visible="shouldShowDeleteDialog"
    :event-id="eventId"
    @hide-dialog="hideDeleteDialog"
    @clear-selection="clearDeletedEventIdFromSelection"
  />

  <div class="card">
    <Toolbar v-if="shouldShowToolbar" class="mb-4">
      <template #start>
        <div class="my-2 flex">
          <EventAddDialog class="mr-2" />
          <EventDeleteSelectedDialog
            :visible="shouldShowDeleteSelectedDialog"
            :selected-events-ids="selectedEventIds"
            @hide-dialog="hideDeleteSelectedDialog"
            @clear-selection="clearSelectedEvents"
          />
        </div>
      </template>

      <template #end>
        <Button
          label="Export"
          icon="pi pi-download"
          class="p-button-secondary"
          @click="exportCSV"
        />
      </template>
    </Toolbar>
    <DataTable
      ref="dataTableReference"
      v-model:filters="filters"
      v-model:selection="selectedEvents"
      responsive-layout="scroll"
      data-testid="event-list"
      :value="events"
      data-key="id"
      :paginator="true"
      :row-hover="true"
      paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows-per-page-options="[5, 10, 20]"
      :rows="10"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      :global-filter-fields="[
        'id',
        'name',
        'region',
        'date_start',
        'date_end',
        'description',
        'lodge.name'
      ]"
    >
      <template #header>
        <div
          class="
            flex flex-column
            md:flex-row md:justify-content-between md:align-items-center
          "
        >
          <h5 class="m-0">Events</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Search..."
            />
          </span>
        </div>
      </template>

      <template #empty> No events found. </template>
      <template #loading> Loading events data. Please wait. </template>
      <Column selection-mode="multiple"></Column>
      <Column field="id" sortable header="ID" class="tw-hidden"></Column>
      <Column field="name" sortable header="Name"></Column>
      <Column field="region" sortable header="Region"></Column>
      <Column
        field="date_start"
        class="tw-whitespace-nowrap"
        sortable
        header="Start Date"
      ></Column>
      <Column
        field="date_end"
        class="tw-whitespace-nowrap"
        sortable
        header="End Date"
      ></Column>
      <Column field="description" sortable header="Description"></Column>
      <Column field="capacity" sortable header="Capacity"></Column>
      <Column field="price" sortable header="Price"></Column>
      <Column field="lodge.name" sortable header="Lodge"></Column>
      <Column>
        <template #body="slotProps">
          <div class="tw-flex tw-flex-nowrap">
            <Button
              icon="pi pi-eye"
              class="p-button p-button-primary mr-2"
              @click="gotoViewEvent(slotProps.data.id)"
            />
            <Button
              icon="pi pi-pencil"
              class="p-button p-button-primary mr-2"
              @click="editEvent(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button p-button-secondary mr-2"
              @click="deleteEvent(slotProps.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import { computed, ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import EventAddDialog from '@/components/EventAddDialog'
import EventEditDialog from '@/components/EventEditDialog'
import EventDeleteDialog from '@/components/EventDeleteDialog'
import EventDeleteSelectedDialog from '@/components/EventDeleteSelectedDialog'

export default {
  components: {
    EventAddDialog,
    EventEditDialog,
    EventDeleteDialog,
    EventDeleteSelectedDialog
  },
  props: {
    events: {
      type: Array,
      required: true
    },
    shouldShowToolbar: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const router = useRouter()
    const dataTableReference = ref(null)
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const shouldShowEditDialog = ref(false)
    const hideEditDialog = () => {
      shouldShowEditDialog.value = false
    }

    const eventState = ref({ event: null })
    const editEvent = (eventData) => {
      eventState.value.event = eventData
      shouldShowEditDialog.value = true
    }

    const eventId = ref(null)
    const selectedEvents = ref([])
    const clearSelectedEvents = () => {
      selectedEvents.value = []
    }
    const clearDeletedEventIdFromSelection = (eventId) => {
      selectedEvents.value = selectedEvents.value.filter(
        (event) => event.id !== eventId
      )
    }

    const selectedEventIds = computed(() =>
      selectedEvents.value.map((event) => event.id)
    )

    const shouldShowDeleteDialog = ref(false)
    const hideDeleteDialog = () => {
      eventId.value = null
      shouldShowDeleteDialog.value = false
    }
    const shouldShowDeleteSelectedDialog = ref(false)
    const hideDeleteSelectedDialog = () => {
      shouldShowDeleteSelectedDialog.value = false
    }

    const gotoViewEvent = (eventId) =>
      router.push({ name: 'ViewEvent', params: { id: eventId } })

    const deleteEvent = (id) => {
      eventId.value = id
      shouldShowDeleteDialog.value = true
    }

    const exportCSV = () => {
      dataTableReference.value.exportCSV()
    }

    return {
      dataTableReference,
      exportCSV,
      filters,
      editEvent,
      eventState,
      shouldShowEditDialog,
      hideEditDialog,
      deleteEvent,
      eventId,
      gotoViewEvent,
      selectedEvents,
      selectedEventIds,
      shouldShowDeleteDialog,
      shouldShowDeleteSelectedDialog,
      clearSelectedEvents,
      clearDeletedEventIdFromSelection,
      hideDeleteDialog,
      hideDeleteSelectedDialog
    }
  }
}
</script>
