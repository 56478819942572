<template>
  <div>
    <Dialog
      v-model:visible="shouldShowDialog"
      header="Confirm delete event"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>
          Are you sure you want to delete the event with ID {{ eventId }}?
        </span>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          role="hide-dialog"
          :disabled="isLoading"
          @click="hideDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-primary"
          role="delete-event"
          :disabled="isLoading"
          :loading="isLoading"
          @click="deleteSingleEvent"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import useEvent from '@/use/useEvent'
import { ref, computed, watch } from 'vue'

export default {
  name: 'EventDeleteDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: Number,
      default: null
    }
  },
  emits: ['hideDialog', 'clearDeletedEventIdFromSelection'],
  setup(props, { emit }) {
    const { fetchEvents, deleteEvent, isLoading } = useEvent()
    const shouldShowDialog = ref(false)

    const isVisible = computed({
      get() {
        return shouldShowDialog.value
      },
      set(value) {
        shouldShowDialog.value = value
      }
    })
    watch(
      () => props.visible,
      (value) => {
        isVisible.value = value
      }
    )

    const showDialog = () => {
      shouldShowDialog.value = true
    }
    const hideDialog = () => {
      shouldShowDialog.value = false
      emit('hideDialog')
    }

    const deleteSingleEvent = async () => {
      deleteEvent(props.eventId).then(() => {
        fetchEvents()
        hideDialog()
        emit('clearDeletedEventIdFromSelection', props.eventId)
      })
    }

    return {
      shouldShowDialog,
      showDialog,
      hideDialog,
      isLoading,
      deleteSingleEvent
    }
  }
}
</script>
